<template>
  <div class="modalbox modalStep" id="waypointModal">
    <div class="is-flex bigmodal">
      <img
        class="btn-previous"
        :src="require('@/assets/img/arrows/previous.svg')"
        @click="goPreviousPage"
      />
      <router-link
        v-if="
          currentUser.role === 'formateur' ||
          currentUser.role === 'coordinateur'
        "
        class="ml-auto mr-2"
        :to="
          '/formations/' + $route.params.idTraining + '/suivi/validationSteps'
        "
      >
        <b-button type="is-outlined" rounded size="is-small"
          >Formation</b-button
        >
      </router-link>
      <router-link
        class="mr-2"
        :to="
          '/formation-apprenant/' +
          $route.params.idTraining +
          '/' +
          $route.params.idStudent +
          '/' +
          $route.params.idStudentTraining +
          '/parcours'
        "
      >
        <b-button type="is-outlined" rounded size="is-small">Parcours</b-button>
      </router-link>
      <router-link
        :to="
          '/formation-apprenant/' +
          $route.params.idTraining +
          '/' +
          $route.params.idStudent +
          '/' +
          $route.params.idStudentTraining +
          '/waypoint'
        "
      >
        <b-button type="is-outlined" rounded size="is-small"
          >Livret de suivi</b-button
        ></router-link
      >
    </div>
    <b-loading :is-full-page="isFullPage" v-model="isLoading"> </b-loading>
    <section class="w-100 container">
      <div class="title-paragraph">
        {{ waypoint.name }}
      </div>
      <!-- <div class="has-text-centered">
        A remplir par : {{ waypoint.editable }}{{ waypoint.status_label }}
      </div> -->
      <div
        class="studentName"
        v-if="currentUser.role !== 'apprenant' && booklet_validation.data"
      >
        {{ this.booklet_validation.data[0].name }}
      </div>
      <div class="has-text-centered mb-2">
        <b-tag type="is-success" v-if="this.validated === 'Effectué'">{{
          this.validated
        }}</b-tag>
        <b-tag type="is-primary" v-else-if="this.validated === 'En cours'">{{
          this.validated
        }}</b-tag>
        <b-tag
          type="is-danger is-light"
          v-else-if="this.validated === 'Litige'"
          >{{ this.validated }}</b-tag
        >
        <b-tag type="is-outlined" v-else-if="this.validated === 'A faire'">{{
          this.validated
        }}</b-tag>
        <b-tag type="is-info" v-else>{{ this.validated }}</b-tag>
      </div>
      <div class="justify-content-center is-flex align-items-center">
        A remplir entre le {{ waypoint.start_date | day }} et le
        {{ waypoint.due_datetime | day }}
        <!-- <b-button>modifier</b-button> -->
      </div>
      <div
        v-html="waypoint.helper"
        class="has-text-centered mb-4 mt-3"
        v-if="waypoint.helper"
      ></div>
      <div v-if="waypoint.resources" class="mt-4">
        <div v-if="waypoint.resources.length">
          <div v-for="resource in waypoint.resources" :key="resource.id">
            <ResourceCardSimple
              :resource="resource"
              :action="'read'"
            ></ResourceCardSimple>
          </div>
        </div>
      </div>
      <div v-if="!idPatch">
        <div class="ck-editor-block mb-0 pb-0" style="display: none">
          <ckeditor
            v-model="formWaypoint.content"
            :editor="editor"
            :config="editorConfig"
            class="mb-5"
            :ref="'revisionContent'"
          ></ckeditor>
        </div>
        <div v-html="formWaypoint.content"></div>
      </div>
      <div v-else>
        <div class="ck-editor-block mb-0 pb-0" style="display: none">
          <ckeditor
            v-model="formWaypoint.entry"
            :editor="editor"
            :config="editorConfig"
            class="mb-5"
            :ref="'revisionContent'"
          ></ckeditor>
        </div>
        <div v-html="formWaypoint.entry"></div>
      </div>
    </section>
    <div v-if="booklet_validation.data" class="container">
      <div
        class="cardValidation"
        v-if="booklet_validation.data[0].data[0].validations.length"
      >
        <div class="mb-3">
          <b class="title-paragraph" style="font-size: 1.2rem"
            >Validations attendues</b
          >
        </div>
        <div class="is-flex align-items-center justify-content-center">
          <div class="b-steps is-small w-100">
            <nav class="steps is-rounded">
              <ul class="step-items">
                <li
                  class="step-item is-previous"
                  v-for="validation in booklet_validation.data[0].data[0]
                    .validations"
                  :key="validation.id"
                >
                  <a class="step-link">
                    <div class="step-marker">
                      <img
                        v-if="validation.status_label == 'Valide'"
                        :src="require(`@/assets/img/steps/status3.svg`)"
                        alt=""
                        class="img_status mx-auto"
                      />

                      <img
                        v-else-if="validation.status_label == 'A faire'"
                        :src="require(`@/assets/img/steps/status6.svg`)"
                        alt=""
                        class="img_status mx-auto"
                      />
                      <img
                        v-else-if="validation.status_label == 'En retard'"
                        :src="require(`@/assets/img/steps/status-late.svg`)"
                        alt=""
                        class="img_status mx-auto"
                      />
                      <img
                        v-else
                        :src="require(`@/assets/img/steps/status5.svg`)"
                        alt=""
                        class="img_status mx-auto"
                      />
                    </div>
                    <div class="step-details">
                      <span class="step-title"> {{ validation.name }}</span>
                    </div>
                    <div class="mt-2">
                      <b-tag
                        v-if="validation.status_label == 'Valide'"
                        type="is-success"
                      >
                        {{ validation.status_label }}</b-tag
                      >
                      <b-tag
                        v-else-if="validation.status_label == 'A faire'"
                        type="is-secondary is-outlined"
                      >
                        {{ validation.status_label }}</b-tag
                      >
                      <b-tag
                        v-else-if="validation.status_label == 'En retard'"
                        class="is-outlined"
                      >
                        {{ validation.status_label }}</b-tag
                      >
                      <b-tag v-else type="is-danger">
                        {{ validation.status_label }}</b-tag
                      >
                      <div
                        class="mt-2 datetime"
                        v-if="validation.validation_datetime"
                      >
                        {{ validation.validation_datetime | day }}
                      </div>
                      <span class="step-name" v-if="validation.validator_name">
                        {{ validation.validator_name }}</span
                      >
                    </div></a
                  >
                </li>
              </ul>
            </nav>

            <!---->
          </div>
        </div>
      </div>
    </div>
    <div>
      <div
        class="is-flex is-flex-wrap-wrap activityBtns align-items-center"
        style="z-index: 2000"
      >
        <div class="w-100 is-flex">
          <div class="mx-auto is-flex">
            <span
              v-if="
                waypoint.editable && new Date(waypoint.start_date) < new Date()
              "
            >
              <span
                v-if="
                  waypoint.editable.includes(currentUser.role) && validation
                "
              >
                <b-button
                  v-if="
                    !validation.status_label ||
                    validation.status_label === 'A faire' ||
                    validation.status_label === 'En retard'
                  "
                  @click="gotoWaypointEdit()"
                  class="btn-action w-auto mr-1"
                  type="is-primary is-rounded is-outlined"
                  rounded
                  >rédiger</b-button
                ></span
              >
            </span>
            <span v-if="new Date(waypoint.start_date) < new Date()">
              <span v-if="validation && waypoint.start_date">
                <b-button
                  v-if="
                    !validation.status_label ||
                    validation.status_label === 'A faire' ||
                    validation.status_label === 'En retard'
                  "
                  @click="validateBookletPart(1)"
                  class="btn-action w-auto"
                  type="is-success is-rounded"
                  rounded
                  >valider</b-button
                ><span
                  v-if="validation.status_label === 'Valide'"
                  class="has-text-success has-text-centered is-flex align-items-center"
                  ><b>Vous avez validé cette fiche</b>
                  <span
                    v-if="
                      waypoint.editable.includes(currentUser.role) && validation
                    "
                  >
                    <b-button
                      v-if="this.validated !== 'Effectué'"
                      @click="gotoWaypointEdit()"
                      class="btn-action w-auto"
                      type="is-primary is-rounded is-outlined"
                      rounded
                      >modifier</b-button
                    ></span
                  >
                </span>
                <span
                  v-else-if="
                    this.validation.status_label === 'Non valide' &&
                    this.validation
                  "
                  class="has-text-danger has-text-centered"
                  ><b>Vous avez refusé de valider cette fiche</b>
                </span>
              </span>
              <span v-else-if="!validation" class="subtitle-paragraph"
                >Cette fiche est en lecture seule pour vous</span
              >
            </span>
            <span v-else
              ><span v-if="validation"
                >Cette fiche pourra être rédigée à partir du
                {{ waypoint.start_date | day }}</span
              >
              <span v-else class="subtitle-paragraph"
                >Cette fiche sera éditée à partir du
                {{ waypoint.start_date | day }}. Elle est en lecture seule pour
                vous</span
              ></span
            >
          </div>

          <div></div>
        </div>
        <div class="help-btn">
          <AppHelpMessage
            title="Modifiez votre point d'étape"
            message="Vous pouvez corriger une faute, ajouter un commentaire mais
             vous ne pouvez plus toucher aux expériences enregistrées une
              fois le point d'étape validé"
            textBtn="Besoin d'aide ?"
          />
        </div>
      </div>
    </div>

    <div class="container contentSequence pb-5 mb-5"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/fr';
import AppHelpMessage from '@/components/AppHelpMessage.vue';
import booklet from '@/components/Waypoints/booklet.json';
import _ from 'lodash';
import ResourceCardSimple from '@/components/Resources/ResourceCardSimple.vue';

export default ({
  filters: {
    day(date) {
      return moment(date).format('D/MM/YYYY');
    },
  },
  components: {
    AppHelpMessage,
    ckeditor: CKEditor.component,
    ResourceCardSimple,
  },
  computed: {
    ...mapState('auth', ['currentUser']),
  },
  data() {
    return {
      idPatch: 0,
      isLoading: false,
      isFullPage: false,
      schoolSubjectsTraining: [],
      schoolSubjects: [],
      schoolSubjectsSelected: [],
      waypoint: [],
      arrayReports: [],
      student_activities_report: [],
      student_revisions_report: [],
      free_situations_report: [],
      formWaypoint: {
        id: '',
        title: '',
        comment: '',
        tutor_comment: '',
        teacher_comment: '',
        start_date: '',
        end_date: '',
        reports: [],
        validated: false,
      },
      checkboxCustom: [],
      activities: [],
      isOpenActivities: false,
      isOpenRevision: false,
      isOpenFree: false,
      editor: ClassicEditor,
      editorDisabled: true,
      editorConfig: {
        language: 'fr',
        toolbar: ['bold', 'italic', 'bulletedList', 'numberedList', '|', 'outdent', 'indent', '|', 'link', '|', 'undo',
          'redo'],
        alignment: {
          options: ['left', 'right'],
        },
        heading: {
          options: [
            { model: 'paragraph', title: 'Styles de texte', class: 'ck-heading_paragraph' },
            {
              model: 'heading1',
              view: 'h1',
              title: 'Titre 1',
              class: 'ck-heading_heading1',
            },
            {
              model: 'heading2',
              view: 'h2',
              title: 'Titre 2',
              class: 'ck-heading_heading2',
            },
            {
              model: 'mark',
              view: 'mark',
              title: 'surligné',
              class: 'ck-mark',
            },
          ],
        },
        table: {
          contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
        },
      },
      bookletJson: booklet,
      booklet_validation: [],
      isDirector: false,
      validation: [],
      validated: false,
    };
  },
  methods: {
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    initDirector() {
      this.$http
        // .get(`/trainings/${this.$route.params.idTraining}/?format=json`)
        .get(`/trainings/${this.$route.params.idTraining}/?query={directors}&format=json`)
        .then((response) => {
          const director = response.data.directors;
          if (director.includes(this.currentUser.id)) {
            this.isDirector = true;
          }
        });
    },
    initBookletPart() {
      const urlWs = `/booklet_parts/${this.$route.params.idPart}/?format=json`;
      this.$http
        .get(`${urlWs}`)
        .then((response) => {
          this.waypoint = response.data;
          this.formWaypoint = this.waypoint;
          const entry = _.findIndex(this.waypoint.entries, ['student_training', Number(this.$route.params.idStudentTraining)]);
          if (entry !== -1) {
            this.formWaypoint.entry = this.waypoint.entries[entry].content;
            this.idPatch = this.waypoint.entries[entry].id;
          }
          this.initBookletValidation();
          this.endLoading();
        });
    },
    initBookletValidation() {
      this.startLoading();
      this.$http
        .get(`/get_training_booklet_validations/${this.$route.params.idTraining}?step=${this.$route.params.idPart}&student_training=${this.$route.params.idStudentTraining}`)
        .then((response) => {
          this.booklet_validation = response.data;
          this.validated = this.booklet_validation.data[0].data[0].user_status_label;
          if (this.isDirector) {
            const indexValidation = _.findKey(this.booklet_validation.data[0].data[0].validations,
              ['role', 'directeur']);
            this.validation = this.booklet_validation.data[0].data[0].validations[indexValidation];
          } else {
            const indexValidation = _.findKey(this.booklet_validation.data[0].data[0].validations,
              ['role', this.currentUser.role]);
            this.validation = this.booklet_validation.data[0].data[0].validations[indexValidation];
          }
        });
    },
    goPreviousPage() {
      // this.$router.push({
      //   name: 'StudentTraining',
      //   params: {
      //     idStudent: this.$route.params.idStudent,
      //     idTraining: this.$route.params.idTraining,
      //     idStudentTraining: this.$route.params.idStudentTraining,
      //     tab: 'waypoint',
      //   },
      // });
      this.$router.go(-1);
    },
    gotoActivity(id, sequenceId) {
      this.$router.push({
        name: 'StudentActivity',
        params: {
          idTraining: this.$route.params.idTraining,
          idStudentTraining: this.$route.params.idStudentTraining,
          idActivity: id,
          idSequence: sequenceId,
          from: this.$route.name,
          idStudent: this.$route.params.idStudent,
          idWaypoint: this.$route.params.idWaypoint,
        },
      });
    },
    gotoRevision(id, studentActivity) {
      const sequence = '1';
      this.$router.push({
        name: 'StudentProgressionRead',
        params: {
          idTraining: this.$route.params.idTraining,
          idStudentTraining: this.$route.params.idStudentTraining,
          idSequence: sequence,
          idActivity: studentActivity,
          idRevision: id,
          from: this.$route.name,
          idStudent: this.$route.params.idStudent,
          idWaypoint: this.$route.params.idWaypoint,
        },
      });
    },
    gotoFreeSituation(id) {
      this.$router.push({
        name: 'StudentFreeSituationRead',
        params: {
          idTraining: this.$route.params.idTraining,
          idStudentTraining: this.$route.params.idStudentTraining,
          idFree: id,
          from: this.$route.name,
          idStudent: this.$route.params.idStudent,
          idWaypoint: this.$route.params.idWaypoint,
        },
      });
    },
    gotoWaypointEdit() {
      this.$router.push({
        name: 'BookletPartEdit',
        params: {
          idTraining: this.$route.params.idTraining,
          idStudent: this.$route.params.idStudent,
          idStudentTraining: this.$route.params.idStudentTraining,
          idPart: this.$route.params.idPart,
        },
      });
    },
    validateBookletPart(statusValidation) {
      this.$buefy.dialog.confirm({
        title: 'Souhaitez-vous valider cette fiche ?',
        message: 'Vous êtes sur le point de valider cette fiche, vous confirmez que vous l\'avez bien relue.',
        confirmText: 'Valider',
        cancelText: 'Relire',
        type: 'is-success',
        hasIcon: true,
        onConfirm: () => {
          if (this.idPatch) {
            this.postValidation(statusValidation);
          } else {
            this.$buefy.dialog.alert({
              title: 'Mince...',
              message: 'Pour valider l\'activité il faut que la fiche soit remplie',
              type: 'is-info',
              hasIcon: true,
              icon: 'times-circle',
              ariaRole: 'alertdialog',
              ariaModal: true,
              confirmText: 'Ok',
            });
          }
        },
      });
    },
    postValidation(statusValidation, identry) {
      let roleValidator = '';
      if (this.isDirector) {
        roleValidator = 'directeur';
      } else {
        roleValidator = this.currentUser.role;
      }
      if (!this.idPatch) {
        this.idPatch = identry;
      }
      this.$http
        .post('/validations/validation/', {
          role: roleValidator,
          student_training: Number(this.$route.params.idStudentTraining),
          simple_booklet_part_validation: this.validation.id,
          status: statusValidation,
        }).then(() => {
          this.$buefy.toast.open({
            message: 'La validation a bien été enregistrée',
            type: 'is-success',
          });
          this.goPreviousPage();
        }).catch((error) => {
          window.console.log(error);
          this.issue = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Votre validation n\'a pas pu être enregistrée',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
  },
  created() {
    this.startLoading();
    this.initBookletPart();
    this.initDirector();
  },
});
</script>

<style lang="scss" scoped>
.card_buttons {
  margin-left: auto !important;
  min-width: 198px;
}
.titles {
  max-width: 460px;
  padding-right: 1rem;
}
.studentName {
  font-weight: bold;
  font-size: 1.4rem;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .card_activity::after {
    content: unset;
    color: #c1bbd0cc;
  }
  .card_activity {
    border-bottom: 1px solid #ededed;
  }
  .card_activity:last-child {
    border-bottom: 0px solid #ededed;
    &::after {
      content: unset;
    }
  }
}
@media screen and (max-width: 576px) {
  .content-steps {
    flex-direction: column;
  }
  .card_buttons {
    margin-left: 0px !important;
    padding-top: 0.5rem;
  }
  // .actions-icon,
  // .img_status {
  //   display: block;
  // }
  .titles {
    flex-direction: column;
  }
}
</style>
