<template>
  <div id="">
    <b-loading :is-full-page="isFullPage" v-model="isLoading"> </b-loading>
    <b-modal v-model="isCardModalActive" scroll="keep" id="modalNude">
      <template #default="props">
        <Modalexport
          @close="props.close"
          :student="student"
          :student_id="$route.params.idStudent"
        ></Modalexport>
      </template>
    </b-modal>
    <div class="columns mt-4">
      <div class="column">
        <div><h1 class="title-paragraph">Livret de suivi</h1></div>
        <div><h2 class="subtitle-paragraph">A chaque fin de période</h2></div>
      </div>
      <div class="column is-narrow">
        <!-- <b-button
          class="button btn btn-secondary is-primary mr-3 is-rounded mb-3"
          @click="newWaypoint()"
        >
          Ajouter un point d'étape</b-button
        >
        <b-button
          class="button btn btn-secondary is-primary mr-3 is-rounded mb-3"
          @click="newAttitudeEvaluation()"
        >
          Ajouter un point comportement</b-button
        > -->

        <b-button
          @click="isCardModalActive = true"
          type="is-primary is-rounded mr-3"
          class="ml-auto"
          rounded
          >exporter le livret
        </b-button>
      </div>
    </div>
    <div class="helpUser mt-0">
      <!-- <div class="title">Ajoutez des points</div> -->
      <div>
        A chaque fin de période en entreprise ou centre de formation, vous
        pouvez ajoutez des points d'étapes pour permettre à votre tuteur ou
        votre centre de formation de suivre ce qui a été étudié et travaillé.
        <b
          >Ces points sont réalisés, en entreprise avec votre tuteur, en centre
          de formation avec votre pilote.</b
        >
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <b-button
          @click="
            typeSelected = 0;
            initWaypoints();
          "
          class="mr-2"
          :class="typeSelected === 0 ? 'is-primary is-light' : ''"
          >Point entreprise</b-button
        ><b-button
          @click="
            typeSelected = 1;
            initWaypoints();
          "
          class="mr-2"
          :class="typeSelected === 1 ? 'is-primary is-light' : ''"
          >Point CFA</b-button
        >
        <b-button
          @click="typeSelected = 2"
          :class="typeSelected === 2 ? 'is-primary is-light' : ''"
          >Point comportement</b-button
        >
      </div>
      <!-- <div class="column is-narrow ml-auto">
        <b-button
          @click="getNotificationsByStatus('read')"
          class="ml-auto"
          :class="status === 'read' ? 'is-success' : ''"
          >Lues</b-button
        >
        <b-button
          @click="getNotificationsByStatus('unread')"
          class="ml-auto"
          :class="status === 'unread' ? 'is-warning' : ''"
          >Non lues</b-button
        >
        <b-button
          @click="getNotificationsByStatus('all')"
          :class="status === null ? 'is-primary' : ''"
          class="ml-auto"
        >
          Tout</b-button
        >
      </div> -->
    </div>
    <div v-if="waypointList.length">
      <div v-for="waypoint in orderDate(waypointList)" :key="waypoint.id">
        <div class="card_activity" v-if="waypoint.type === typeSelected">
          <div
            class="activity-item is-flex align-items-center"
            :class="new Date(waypoint.start_date) > today ? 'date-later' : ''"
          >
            <div>
              <img
                v-if="waypoint.validated"
                :src="require(`@/assets/img/steps/status3.svg`)"
                alt=""
                class="img_status mr-3"
              />
              <img
                v-else
                :src="require(`@/assets/img/steps/status6.svg`)"
                alt=""
                class="img_status mr-3"
              />
            </div>
            <div class="columns is-tablet mb-0 ml-1 is-vcentered">
              <!-- <div class="mr-2">
              <b>{{ index + 1 }}.</b>
            </div> -->
              <div class="dates-line">
                <div class="dates mr-1">du {{ waypoint.start_date | day }}</div>
                <div class="dates mr-3">au {{ waypoint.end_date | day }}</div>
              </div>
              <div class="title_activity" v-if="waypoint.type === 1">
                <!-- <span class="tag status1">point CFA</span> -->
                <span
                  v-if="
                    currentUser.role == 'tuteur' ||
                    currentUser.role == 'coordinateur' ||
                    currentUser.role == 'formateur'
                  "
                >
                  <span
                    class="tag is-success mr-0 w-auto"
                    v-if="waypoint.read_by_tutor"
                    >lu par le tuteur</span
                  ><span v-else class="tag is-warning mr-0 w-auto"
                    >non lu par le tuteur</span
                  ></span
                >
                <div v-if="waypoint.title">{{ waypoint.title }}</div>
                <div v-else>Point centre de formation</div>
              </div>
              <div class="title_activity" v-else-if="waypoint.type === 0">
                <!-- <span class="tag status2">point entreprise</span> -->
                <span
                  v-if="
                    currentUser.role == 'tuteur' ||
                    currentUser.role == 'coordinateur' ||
                    currentUser.role == 'formateur'
                  "
                >
                  <span
                    class="tag is-success mr-0 w-auto"
                    v-if="waypoint.read_by_tutor"
                    >lu par le tuteur</span
                  ><span v-else class="tag is-warning mr-0 w-auto"
                    >non lu par le tuteur</span
                  ></span
                >
                <div v-if="waypoint.title">{{ waypoint.title }}</div>
                <div v-else>Point entreprise</div>
              </div>
            </div>
            <div
              class="ml-auto is-flex align-items-center"
              v-if="
                (!waypoint.validated &&
                  waypoint.type === 0 &&
                  currentUser.role === 'tuteur') ||
                (!waypoint.validated &&
                  waypoint.type === 0 &&
                  currentUser.role === 'apprenant') ||
                (!waypoint.validated &&
                  waypoint.type === 1 &&
                  currentUser.role === 'apprenant') ||
                (!waypoint.validated && currentUser.role === 'coordinateur') ||
                (!waypoint.validated && currentUser.role === 'formateur')
              "
            >
              <b-button
                outlined
                rounded
                type="is-primary"
                @click="gotoWaypointEdit(waypoint.id)"
                >rédiger</b-button
              >
              <div
                class="actions-icon is-clickable"
                @click.prevent="removeWaypoint(waypoint.id)"
                v-if="
                  (!waypoint.validated &&
                    currentUser.role === 'coordinateur') ||
                  (!waypoint.validated && currentUser.role === 'formateur')
                "
              >
                <b-icon icon="close" size="is-medium" type="is-danger">
                </b-icon>
              </div>
            </div>
            <b-button
              outlined
              rounded
              type="is-primary"
              class="ml-auto"
              v-else
              @click="gotoWaypoint(waypoint.id)"
              >voir</b-button
            >
            <div
              class="actions-icon is-clickable"
              @click.prevent="removeWaypoint(waypoint.id)"
              v-if="currentUser.role === 'coordinateur' && waypoint.validated"
            >
              <b-icon icon="close" size="is-medium" type="is-danger"> </b-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-message
      type="is-info"
      v-if="
        (!waypointList.length && typeSelected === 0) ||
        (!waypointList.length && typeSelected === 1)
      "
      >Il n'y a pas encore de point d'étape enregistré.
    </b-message>
    <div v-if="AttitudeWaypointList.length && typeSelected === 2">
      <div v-if="AttitudeWaypointList.length">
        <div
          v-for="AttitudeWaypoint in orderDate(AttitudeWaypointList)"
          :key="AttitudeWaypoint.id"
          class="card_activity"
        >
          <div
            class="activity-item is-flex align-items-center"
            :class="
              new Date(AttitudeWaypoint.start_date) > today ? 'date-later' : ''
            "
          >
            <div>
              <img
                v-if="AttitudeWaypoint.validated"
                :src="require(`@/assets/img/steps/status3.svg`)"
                alt=""
                class="img_status mr-3"
              />
              <img
                v-else
                :src="require(`@/assets/img/steps/status6.svg`)"
                alt=""
                class="img_status mr-3"
              />
            </div>
            <div class="columns is-tablet mb-0 ml-1 is-vcentered">
              <!-- <div class="mr-2">
              <b>{{ index + 1 }}.</b>
            </div> -->
              <div class="dates-line">
                <div class="dates mr-1">
                  du {{ AttitudeWaypoint.start_date | day }}
                </div>
                <div class="dates mr-3">
                  au {{ AttitudeWaypoint.end_date | day }}
                </div>
              </div>
              <div class="title_activity">
                <span
                  v-if="
                    currentUser.role == 'tuteur' ||
                    currentUser.role == 'coordinateur' ||
                    currentUser.role == 'formateur'
                  "
                >
                  <span
                    class="tag is-success mr-0 w-auto ml-1"
                    v-if="AttitudeWaypoint.read_by_tutor"
                    >lu par le tuteur</span
                  ><span v-else class="tag is-warning mr-0 w-auto"
                    >non lu par le tuteur</span
                  ></span
                >
                <div v-if="AttitudeWaypoint.title">
                  <b> {{ AttitudeWaypoint.title }}</b>
                </div>
                <div v-else><b> Point comportement</b></div>
              </div>
              <div class="title_activity" v-if="AttitudeWaypoint.type === 1">
                <span class="tag status1">point CFA</span>
                <span
                  v-if="
                    currentUser.role == 'tuteur' ||
                    currentUser.role == 'coordinateur' ||
                    currentUser.role == 'formateur'
                  "
                >
                  <span
                    class="tag is-success mr-0 w-auto ml-1"
                    v-if="AttitudeWaypoint.read_by_tutor"
                    >lu</span
                  ><span v-else class="ml-1 tag is-warning mr-0 w-auto"
                    >non lu</span
                  ></span
                >
                <div v-if="AttitudeWaypoint.title">
                  {{ AttitudeWaypoint.title }}
                </div>
                <div v-else>Point comportement</div>
              </div>
            </div>
            <div
              class="ml-auto is-flex align-items-center"
              v-if="
                (!AttitudeWaypoint.validated &&
                  currentUser.role === 'tuteur') ||
                (!AttitudeWaypoint.validated &&
                  currentUser.role === 'formateur')
              "
            >
              <b-button
                outlined
                rounded
                type="is-primary"
                @click="gotoAttitudeWaypointEdit(AttitudeWaypoint.id)"
                >rédiger</b-button
              >
              <div
                class="actions-icon is-clickable"
                @click.prevent="removeWaypoint(AttitudeWaypoint.id)"
                v-if="
                  (!AttitudeWaypoint.validated &&
                    currentUser.role === 'coordinateur') ||
                  (!AttitudeWaypoint.validated &&
                    currentUser.role === 'formateur')
                "
              >
                <b-icon icon="close" size="is-medium" type="is-danger">
                </b-icon>
              </div>
            </div>
            <b-button
              v-else
              outlined
              rounded
              type="is-primary"
              class="ml-auto"
              @click="gotoAttitudeWaypoint(AttitudeWaypoint.id)"
              >voir</b-button
            >
          </div>
        </div>
      </div>
    </div>
    <b-message
      v-if="!AttitudeWaypointList.length && typeSelected === 2"
      type="is-info"
      >Il n'y a pas encore de point comportement enregistré.</b-message
    >
    <!-- <div v-if="AttitudeWaypointList.length && typeSelected === 2">
      <div
        v-for="AttitudeWaypoint in AttitudeWaypointList"
        :key="AttitudeWaypoint.id"
        class="card_activity"
      >
        <div class="activity-item is-flex align-items-center">
          <div>
            <img
              :src="require(`@/assets/img/steps/status3.svg`)"
              alt=""
              class="img_status mr-3"
            />
          </div>
          <div class="columns is-tablet mb-0 ml-1 is-vcentered">
            <div class="dates-line">
              <div class="dates mr-1">
                du {{ AttitudeWaypoint.start_date | day }}
              </div>
              <div class="dates mr-3">
                au {{ AttitudeWaypoint.end_date | day }}
              </div>
            </div>
            <div class="title_activity">Point comportement</div>
          </div>
          <b-button
            outlined
            rounded
            type="is-primary"
            class="ml-auto"
            @click="gotoAttitudeWaypoint(AttitudeWaypoint.id)"
            >voir</b-button
          >
        </div>
      </div>
    </div> -->

    <b-button
      v-if="studentWaypointCreate && currentUser.role === 'apprenant'"
      class="button btn btn-secondary is-primary mr-3 is-rounded mb-5"
      @click="newWaypoint()"
    >
      Ajouter un point d'étape</b-button
    >
    <!-- <b-button
      class="button btn btn-secondary is-primary mr-3 is-rounded mb-3"
      @click="newAttitudeEvaluation()"
    >
      Ajouter un point comportement</b-button
    > -->
    <!-- <div class="title-paragraph mt-5">Evaluation de comportement</div>
    <div class="helpUser mt-0">
      <div>
        Les évaluations de comportement sont à réalisées moins souvent, au moins
        3 fois dans l'année.
        <b>Elles sont réalisées avec le tuteur ou maître d'apprentissage.</b>
      </div>
    </div>
    <div v-if="AttitudeWaypointList.length">
      <div
        v-for="(AttitudeWaypoint, index) in AttitudeWaypointList"
        :key="AttitudeWaypoint.id"
        class="card_activity"
      >
        <div class="activity-item is-flex align-items-center">
          <div>
            <img
              :src="require(`@/assets/img/steps/status3.svg`)"
              alt=""
              class="img_status mr-3"
            />
          </div>
          <div class="columns is-tablet mb-0 ml-1 is-vcentered">
            <div class="mr-2">
              <b>{{ index + 1 }}.</b>
            </div>
            <div class="is-flex">
              <div class="dates mr-1">
                {{ AttitudeWaypoint.start_date | day }} -
              </div>
            </div>
            <div class="title_activity">Point comportement</div>
          </div>
          <b-button
            outlined
            rounded
            type="is-primary"
            class="ml-auto"
            @click="gotoAttitudeWaypoint(AttitudeWaypoint.id)"
            >voir</b-button
          >
        </div>
      </div>
    </div> -->
    <!-- <b-message type="is-info" v-if="!AttitudeWaypointList.length"
      >Il n'y a pas encore de point de comportement enregistré.
    </b-message>

    <b-button
      class="button btn btn-secondary is-primary mr-3 is-rounded mb-3"
      @click="newAttitudeEvaluation()"
    >
      Ajouter un point comportement</b-button
    > -->
    <b-modal
      v-model="isWaypointModalActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal
      id="modalNude"
    >
      <template>
        <div
          class="modal-card modal-users has-text-centered pb-2"
          style="width: auto"
          id="modalWaypoint"
        >
          <section class="modal-card-body">
            <div class="has-text-right">
              <a @click="isWaypointModalActive = false" class="ml-auto">
                <b-icon icon="close" type="is-primary"></b-icon>
              </a>
            </div>
            <div class="has-text-small mb-2-">
              <b class="title-paragraph"
                >Définissez le point d'étape à ajouter</b
              >
            </div>
            <b-field class="column is-narrow mr-3 mb-0">
              <b-select
                placeholder="Type de situation"
                icon="earth"
                v-model="formWaypoint.type"
              >
                <option value="1" v-if="currentUser.role !== 'tuteur'">
                  Point en centre de formation
                </option>
                <option value="0">Point en entreprise</option>
                <option value="2" v-if="currentUser.role !== 'apprenant'">
                  Point comportement
                </option>
              </b-select>
            </b-field>
            <!-- <div v-else>Point en entreprise {{ (formWaypoint.type = 0) }}</div> -->
            <div class="columns is-tablet is-vcentered mb-0">
              <div class="column is-narrow">
                <b-field label="Date de début de période">
                  <b-datepicker
                    v-model="formWaypoint.start_date"
                    :locale="locale"
                    :show-week-number="showWeekNumber"
                    :first-day-of-week="1"
                    icon="calendar-today"
                    trap-focus
                    inline
                  >
                  </b-datepicker>
                </b-field>
              </div>
              <div class="column is-narrow">
                <b-field label="Date de fin de période">
                  <b-datepicker
                    v-model="formWaypoint.end_date"
                    :show-week-number="showWeekNumber"
                    :locale="locale"
                    icon="calendar-today"
                    trap-focus
                    inline
                    :first-day-of-week="1"
                  >
                  </b-datepicker>
                </b-field>
              </div>
            </div>
            <b-button
              type="is-primary"
              @click="postAttitudeWaypoint()"
              v-if="formWaypoint.type === '2'"
              :disabled="multiple_value_boolean === false"
              >Ajouter le point comportement</b-button
            >
            <b-button
              type="is-primary"
              style="margin-top: -10px"
              v-else
              @click="postWaypoint()"
              :disabled="multiple_value_boolean === false"
              >Ajouter le point d'étape</b-button
            >
          </section>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import _ from 'lodash';
import moment from 'moment';
import Modalexport from '@/components/Waypoints/Modalexport.vue';
// import localization from 'moment/locale/fr';

export default ({
  props: {
    student: {
      type: [String],
    },
  },
  components: {
    Modalexport,
  },
  filters: {
    day(date) {
      return moment(date).format('DD/MM/YYYY');
    },
  },
  computed: {
    ...mapState('auth', ['currentUser']),
    multiple_value_boolean() {
      return this.formWaypoint.type !== null
        && this.formWaypoint.start_date !== null
        && this.formWaypoint.end_date !== null;
    },
  },
  data() {
    return {
      isLoading: false,
      isFullPage: false,
      isCardModalActive: false,
      schoolSubjects: [],
      waypointList: [],
      AttitudeWaypointList: [],
      isWaypointModalActive: false,
      formWaypoint: {
        type: null,
        end_date: null,
        start_date: null,
      },
      showWeekNumber: true,
      locale: 'UTC',
      today: new Date(),
      typeSelected: 0,
      trainingOptions: [],
      studentWaypointCreate: false,
    };
  },
  methods: {
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    order(array) {
      return _.orderBy(array, 'position');
    },
    orderDate(array) {
      return _.orderBy(array, 'start_date');
    },
    initWaypoints() {
      this.startLoading();
      const urlWs = `/agenda/student_training_waypoints/?student_training=${this.$route.params.idStudentTraining}&type=${this.typeSelected}&format=json`;
      this.$http
        .get(`${urlWs}`)
        .then((response) => {
          this.waypointList = this.order(response.data);
          this.initAttitudeWaypoints();
          this.endLoading();
          if (this.currentUser.role === 'tuteur') {
            this.formWaypoint.type = 0;
          }
        });
    },
    initAttitudeWaypoints() {
      const urlWs = `/agenda/student_training_attitude_waypoints/?student_training=${this.$route.params.idStudentTraining}&format=json`;
      this.$http
        .get(`${urlWs}`)
        .then((response) => {
          this.AttitudeWaypointList = response.data;
          this.endLoading();
        });
    },
    newWaypoint() {
      this.isWaypointModalActive = true;
    },
    newAttitudeEvaluation() {
      this.$router.push({
        name: 'AttitudeEvaluationEdit',
        params: {
          idTraining: this.$route.params.idTraining,
          idStudent: this.$route.params.idStudent,
          idStudentTraining: this.$route.params.idStudentTraining,
          idWaypoint: 1,
        },
      });
    },

    postWaypoint() {
      this.$http
        .post('agenda/student_training_waypoints/', {
          start_date: this.formWaypoint.start_date,
          end_date: this.formWaypoint.end_date,
          type: this.formWaypoint.type,
          student_training: this.$route.params.idStudentTraining,
        }).then((response) => {
          this.$buefy.toast.open({ message: 'Point d\'étape enregistrée', type: 'is-success' });
          this.initWaypoints();
          this.gotoWaypointEdit(response.data.id);
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'L\'activité n\'a pas pu être enregistrée.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    postAttitudeWaypoint() {
      this.$http
        .post('agenda/student_training_attitude_waypoints/', {
          start_date: this.formWaypoint.start_date,
          end_date: this.formWaypoint.end_date,
          student_training: this.$route.params.idStudentTraining,
          title: this.formWaypoint.title,
          goals: this.formWaypoint.goals,
        }).then((response) => {
          this.$buefy.toast.open({ message: 'Point d\'étape enregistrée', type: 'is-success' });
          this.initWaypoints();
          this.gotoAttitudeWaypointEdit(response.data.id);
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    // postAttitudeWaypoint() {
    //   this.$http
    //     .post('agenda/student_training_attitude_waypoints/', {
    //       start_date: this.formWaypoint.start_date,
    //       end_date: this.formWaypoint.end_date,
    //       type: this.formWaypoint.type,
    //       student_training: this.$route.params.idStudentTraining,
    //     }).then((response) => {
    //       this.$buefy.toast.open({ message: 'Point d\'étape enregistrée', type: 'is-success' });
    //       this.initWaypoints();
    //       this.gotoAttitudeWaypointEdit(response.data.id);
    //     }).catch((error) => {
    //       window.console.log(error);
    //       this.errored = true;
    //       this.$buefy.dialog.alert({
    //         title: 'Mince...',
    //         message: 'L\'activité n\'a pas pu être enregistrée.',
    //         type: 'is-info',
    //         hasIcon: true,
    //         icon: 'times-circle',
    //         ariaRole: 'alertdialog',
    //         ariaModal: true,
    //         confirmText: 'Ok',
    //       });
    //     });
    // },
    gotoWaypointEdit(id) {
      this.$router.push({
        name: 'WaypointEdit',
        params: {
          idTraining: this.$route.params.idTraining,
          idStudent: this.$route.params.idStudent,
          idStudentTraining: this.$route.params.idStudentTraining,
          idWaypoint: id,
        },
      });
    },
    gotoAttitudeWaypointEdit(id) {
      this.$router.push({
        name: 'AttitudeEvaluationEdit',
        params: {
          idTraining: this.$route.params.idTraining,
          idStudent: this.$route.params.idStudent,
          idStudentTraining: this.$route.params.idStudentTraining,
          idWaypoint: id,
        },
      });
    },
    gotoAttitudeWaypoint(id) {
      this.$router.push({
        name: 'AttitudeEvaluationRead',
        params: {
          idTraining: this.$route.params.idTraining,
          idStudent: this.$route.params.idStudent,
          idStudentTraining: this.$route.params.idStudentTraining,
          idWaypoint: id,
        },
      });
    },
    gotoWaypoint(id) {
      this.$router.push({
        name: 'WaypointRead',
        params: {
          idTraining: this.$route.params.idTraining,
          idStudent: this.$route.params.idStudent,
          idStudentTraining: this.$route.params.idStudentTraining,
          idWaypoint: id,
        },
      });
    },
    removeWaypoint(id) {
      const urlWs = `/agenda/student_training_waypoints/${id}/`;
      this.$buefy.dialog.confirm({
        title: 'Supprimer un point d\'étape',
        message: 'Êtes-vous sûr de vouloir supprimer ce point ? Attention, vous perdrez tout le contenu que vous avez pu rédiger pour ce point.',
        confirmText: 'Supprimer',
        cancelText: 'Annuler',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$http
            .delete(`${urlWs}`)
            .then(() => {
              this.initWaypoints();
              this.$buefy.toast.open({ message: 'Point supprimé', type: 'is-success' });
            }).catch((error) => {
              window.console.log(error);
              this.errored = true;
              this.$buefy.dialog.alert({
                title: 'Mince...',
                message: 'Le point n\'a pas pu être supprimé.',
                type: 'is-info',
                hasIcon: true,
                icon: 'times-circle',
                ariaRole: 'alertdialog',
                ariaModal: true,
                confirmText: 'Ok',
              });
            });
        },
      });
    },
    initOptions() {
      this.startLoading();
      this.$http
        .get(`/trainings/${this.$route.params.idTraining}/?query={tab_options}&format=json`)
        .then((response) => {
          this.trainingOptions = response.data.tab_options;
          if (this.trainingOptions.find((option) => option.code === 'studentWaypointCreate')) {
            this.studentWaypointCreate = true;
          }
        }).catch((error) => {
          window.console.log(error);
        });
    },
  },
  created() {
    this.startLoading();
    this.initWaypoints();
    this.initOptions();
  },
  mounted() {
  },
  destroyed() {
  },
});
</script>

<style lang="scss" scoped>
#modalWaypoint {
  border-radius: 5px;
  padding-bottom: 3rem;
}
#modalWaypoint .modal-card-body .icon {
  font-size: 0.8rem !important;
}
#bloc-activities {
  background-color: unset;
  padding-top: 0px;
}
.dates {
  font-size: 0.95rem;
}
.title_activity {
  font-size: 1rem;
}
.tag {
  margin-bottom: 0.2rem;
}
@media (max-width: 768px) {
  .modal .animation-content,
  .modal-card-body,
  .modal-card {
    width: 100%;
    height: 100%;
    max-height: 100vh;
    margin: 0;
  }
  #modalWaypoint {
    border-radius: 0px;
  }
  .dates-line,
  .title_activity {
    display: flex;
    flex-direction: row;
    .tag {
      margin-right: 0.5rem;
    }
  }
  .dates-line {
    margin-bottom: 0.3rem;
  }
}
@media (max-width: 576px) {
  .card_activity::after {
    padding-left: 1.1rem;
  }
  #bloc-activities .title_activity,
  .dates {
    font-size: 0.95rem;
  }
  .modal .title-paragraph {
    padding-right: 2rem;
    padding-left: 2rem;
    font-size: 1.3rem;
  }
}
@media (max-width: 475px) {
  #modalWaypoint {
    padding: 1rem 0px 3rem;
    .modal-card {
      padding-top: 1rem;
    }
    .modal-card-body {
      padding-left: 0px;
      padding-right: 0px;
    }
    .icon {
      padding-right: 1.5rem;
    }
  }
  .datepicker {
    .dropdown-item {
      padding: 0px;
    }
  }
  .title_activity {
    font-size: 1rem;
    line-height: 1.2;
  }
  .dates {
    font-size: 0.8rem;
    line-height: 1.2;
  }
  .activity-item .button {
    font-size: 0.8rem;
  }
  .title_activity {
    display: block;
    flex-direction: column;
  }
}
.date-later {
  opacity: 0.6;
}
.button.is-primary.is-light:hover {
  background-color: #fff !important;
}
@media (max-width: 768px) {
  .helpUser {
    display: none;
  }
} // @media (max-width: 325px) {
//    .datepicker {
//      margin-left:-0.5rem;
//    }
// }
</style>
