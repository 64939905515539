<template>
  <div class="modalbox modalStep modalRead">
    <div class="is-flex bigmodal">
      <div class="mr-auto">
        <img
          class="btn-previous"
          :src="require('@/assets/img/arrows/previous.svg')"
          @click="$emit('close')"
        />
      </div>
    </div>
    <section class="w-100">
      <b-carousel
        v-model="carousel"
        :animated="animated"
        :has-drag="drag"
        :autoplay="autoPlay"
        :pause-hover="pauseHover"
        :pause-info="pauseInfo"
        :pause-info-type="pauseType"
        :interval="interval"
        :repeat="repeat"
        :arrow-hover="false"
        :indicator="false"
        @change="saveStep()"
      >
        <b-carousel-item
          v-for="(stepActivity, i) in order(
            studentactivity.student_activity_steps
          )"
          :key="i"
        >
          <section
            class="hero is-medium is-bold"
            v-if="
              isTutorEvaluation &&
              studentactivity.rights.includes('w_tuteur') &&
              studentactivity.status === 3
            "
          >
            <div class="">
              <div class="bloc-title" v-if="currentUser.role === 'tuteur'">
                <div class="title-paragraph">
                  Comparez votre évaluation avec celle de votre apprenant•e
                </div>
                <div>
                  <div class="subtitle-paragraph mb-4 test">
                    <div>
                      Comparez maintenant vos évaluations avec celles de votre
                      apprenant•e. L'enjeu est de bien discuter avec votre
                      apprenant•e de ce qui a été vu, travaillé, acquis et
                      l'aider à identifier et comprendre où il en est. Vous
                      pouvez encore modifier et ajuster en fonction de la
                      discussion avec votre apprenant•e. Toutefois, si vraiment
                      vous n'êtes pas d'accord sur le constat, vous pouvez
                      indiquer un litige. Cela permettra à l'équipe pédagogique
                      d'identifier qu'il y a un souci.
                    </div>
                  </div>
                </div>
                <div class="has-text-centered">
                  <b-button
                    type="is-success"
                    class="mr-2"
                    @click="validateDefEval"
                    rounded
                    >valider</b-button
                  ><b-button
                    type="is-danger"
                    rounded
                    outlined
                    @click="conflictAlert()"
                    >indiquer un litige</b-button
                  >
                </div>
                <SkillsLevelsComparison
                  v-if="studentactivity.rights.includes('w_tuteur')"
                  :role="studentactivity.rights"
                  type="sequence"
                />
                <div class="has-text-centered pb-5 mb-5">
                  <b-button
                    type="is-success"
                    class="mr-2"
                    @click="validateDefEval"
                    rounded
                    >valider</b-button
                  ><b-button
                    type="is-danger"
                    rounded
                    outlined
                    @click="conflictAlert()"
                    >indiquer un litige</b-button
                  >
                </div>
              </div>
              <div
                class="bloc-title"
                v-else-if="currentUser.role === 'apprenant'"
              >
                <div class="title-paragraph">Évaluation comparée</div>

                <div>
                  <div class="subtitle-paragraph mb-4 test">
                    <div>
                      Comparez maintenant les évaluations réalisées avec votre
                      tuteur. L'enjeu est de bien discuter ensemble de ce qui a
                      été vu, travaillé, acquis et vous aider à identifier et
                      comprendre où vous en êtes.
                    </div>
                  </div>
                </div>
                <!-- <div class="has-text-centered">
                  <b-button type="is-danger" @click="conflictAlert()"
                    >indiquer un litige</b-button
                  >
                </div> -->
                <SkillsLevelsComparison
                  v-if="studentactivity.rights.includes('w_tuteur')"
                  :role="studentactivity.rights"
                  type="sequence"
                />
                <!-- <div class="has-text-centered pb-5 mb-5">
                  <b-button
                    type="is-success"
                    class="mr-2"
                    @click="validateDefEval"
                    >valider</b-button
                  ><b-button type="is-danger" @click="conflictAlert()"
                    >indiquer un litige</b-button
                  >
                </div> -->
              </div>
              <!-- <div
                class="bloc-title"
                v-if="currentUser.role === 'tuteur' && !tutorCanReadEvaluation"
              >
                <b-message
                  >Vous devez d'abord réaliser votre évaluation pour visualiser
                  celle de votre apprenant</b-message
                >
              </div> -->
              <div class="bloc-title" v-else>
                <div class="title-paragraph">Évaluation comparée</div>

                <div>
                  <div class="subtitle-paragraph mb-4 test">
                    <div>
                      Comparez maintenant les évaluations réalisées par chacun.
                    </div>
                  </div>
                </div>
                <!-- <div class="has-text-centered">
                  <b-button type="is-danger" @click="conflictAlert()"
                    >indiquer un litige</b-button
                  >
                </div> -->
                <SkillsLevelsComparison
                  v-if="studentactivity.rights.includes('w_tuteur')"
                  :role="studentactivity.rights"
                  type="sequence"
                />
                <!-- <div class="has-text-centered pb-5 mb-5">
                  <b-button
                    type="is-success"
                    class="mr-2"
                    @click="validateDefEval"
                    >valider</b-button
                  ><b-button type="is-danger" @click="conflictAlert()"
                    >indiquer un litige</b-button
                  >
                </div> -->
              </div>
            </div>
          </section>
          <section
            class="hero is-medium is-bold"
            v-else-if="
              isTutorEvaluation &&
              studentactivity.rights.includes('w_tuteur') &&
              studentactivity.status === 4
            "
          >
            <div class="">
              <div class="bloc-title">
                <div class="has-text-centered">
                  <b-tag type="is-success">validé</b-tag>
                </div>
                <div class="title-paragraph">Évaluation comparée</div>
                <div>
                  <div class="subtitle-paragraph mb-4 test">
                    <div>
                      Les évaluations tuteur/apprenant ont été comparées et
                      validées par le tuteur.
                    </div>
                  </div>
                </div>
                <SkillsLevelsComparison
                  v-if="studentactivity.rights.includes('w_tuteur')"
                  :role="studentactivity.rights"
                  type="sequence"
                  :nomodify="true"
                />
              </div>
            </div>
          </section>
          <section
            class="hero is-medium is-bold"
            v-else-if="
              isTutorEvaluation &&
              studentactivity.rights.includes('w_tuteur') &&
              studentactivity.status === 7
            "
          >
            <div class="">
              <div class="bloc-title">
                <div class="has-text-centered">
                  <b-tag type="is-danger is-light">litige</b-tag>
                </div>
                <div class="title-paragraph">Évaluation comparée</div>
                <div>
                  <div class="subtitle-paragraph mb-4 test">
                    <div>
                      Les évaluations tuteur/apprenant ont été comparées et le
                      tuteur a indiqué un litige.
                    </div>
                    <b-button
                      rounded
                      class="mt-3"
                      type="is-success"
                      @click="endConflict()"
                      v-if="
                        currentUser.role === 'formateur' ||
                        currentUser.role === 'coordinateur'
                      "
                      >Lever le litige</b-button
                    >
                  </div>
                </div>
                <SkillsLevelsComparison
                  v-if="studentactivity.rights.includes('w_tuteur')"
                  :role="studentactivity.rights"
                  type="sequence"
                  :nomodify="true"
                />
                <div class="has-text-centered">
                  <b-button
                    rounded
                    class="mt-3"
                    type="is-success"
                    @click="endConflict()"
                    v-if="
                      currentUser.role === 'formateur' ||
                      currentUser.role === 'coordinateur'
                    "
                    >Lever le litige</b-button
                  >
                </div>
              </div>
            </div>
          </section>
          <section class="hero is-medium is-bold" v-else>
            <div class="">
              <div class="w-100 bloc-title">
                <div class="title-paragraph">
                  <div class="stepsCount">
                    {{ i + 1 }} /
                    {{ studentactivity.student_activity_steps.length }}
                  </div>
                  {{ stepActivity.legacy_activity_step.name }}
                </div>
                <div
                  v-if="sequencetype === 10 && studentactivity.position === 1"
                >
                  <div class="subtitle-paragraph mb-4">
                    <div
                      v-html="
                        stepActivity.legacy_activity_step.description_override
                      "
                    ></div>
                  </div>
                </div>
                <div class="subtitle-paragraph mb-4" v-else>
                  <div v-if="stepActivity.legacy_activity_step.content">
                    <div
                      v-html="stepActivity.legacy_activity_step.content"
                      :class="
                        stepActivity.legacy_activity_step.content.length > 200
                          ? 'readonly'
                          : ''
                      "
                    ></div>
                  </div>
                  <div v-else>
                    <div
                      v-html="
                        stepActivity.legacy_activity_step.placeholder_override
                      "
                      :class="
                        stepActivity.legacy_activity_step.placeholder_override
                          .length > 200
                          ? 'readonly'
                          : ''
                      "
                    ></div>
                  </div>
                </div>
              </div>
              <div class="w-100 container-editor">
                <div
                  v-if="stepActivity.legacy_activity_step.content_type === 1"
                >
                  <div v-if="studentactivity.rights.includes('w_tuteur')">
                    <SkillsLevelsBlocks
                      v-if="
                        currentUser.role === 'tuteur' && !tutorCanReadEvaluation
                      "
                      :role="studentactivity.rights"
                      type="sequence"
                    />
                    <SkillsLevelsComparison
                      v-else
                      :role="studentactivity.rights"
                      type="sequence"
                    />
                  </div>
                  <div v-else>
                    <b-message
                      type="is-warning"
                      v-if="
                        currentUser.role === 'tuteur' && !tutorCanReadEvaluation
                      "
                      >Vous devez d'abord réaliser votre évaluation pour
                      visualiser celle de votre apprenant</b-message
                    >
                    <SkillsLevelsBlocks
                      v-else
                      :role="studentactivity.rights"
                      type="sequence"
                    />
                  </div>
                </div>
                <div v-if="stepActivity.step_entry">
                  <div
                    v-if="
                      currentUser.role === 'tuteur' &&
                      !tutorCanReadEvaluation &&
                      stepActivity.legacy_activity_step.content_type === 1
                    "
                  ></div>
                  <div
                    v-else
                    class="card_grey card_edit ck"
                    v-html="stepActivity.step_entry"
                  ></div>
                </div>
                <b-message
                  type="is-info"
                  v-else-if="
                    stepActivity.legacy_activity_step.content_type !== 2
                  "
                  >Il n'y pas de commentaire enregistré.</b-message
                >
              </div>
            </div>
          </section>
        </b-carousel-item>
      </b-carousel>
      <div>
        <div
          class="is-flex is-flex-wrap-wrap activityBtns align-items-center"
          style="z-index: 2000"
          v-if="studentactivity.rights.includes('w_' + currentUser.role)"
        >
          <!-- <b-button @click="$emit('close')" class="mr-auto" rounded
            ><b-icon icon="left" class="mr-3"></b-icon>retour</b-button
          > -->
          <div class="w-100 is-flex">
            <b-button
              v-if="
                studentactivity.status === 3 ||
                studentactivity.status === 4 ||
                studentactivity.status === 7
              "
              @click="modifyStatus"
              rounded
              class="mx-auto btn-action"
              >modifier</b-button
            >
            <b-button
              v-else-if="edit === true && studentactivity.status === 6"
              @click="validateActivity"
              type="is-success is-rounded mr-3"
              rounded
              class="mx-auto btn-action"
              >valider l'activité</b-button
            >
          </div>
          <div class="help-btn">
            <AppHelpMessage
              title="Modifiez votre activité"
              message="Vous pouvez modifier votre activité pour corriger des fautes
                 ou reprendre le contenu.
               <b>Toutefois si vous refaites cette situation, il vaut mieux utiliser le bouton
                  'Vous avez progressé' sur la vue précédente pour ajouter de nouvelles notes.</b>"
              textBtn="Besoin d'aide ?"
            />
          </div>
        </div>
      </div>
    </section>

    <b-loading :is-full-page="isFullPage" v-model="isLoading"> </b-loading>

    <div class="container contentSequence pb-5 mb-5"></div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import moment from 'moment';
import localization from 'moment/locale/fr';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/fr';
import _ from 'lodash';
import AppHelpMessage from '@/components/AppHelpMessage.vue';
import SkillsLevelsBlocks from '@/components/Skills/SkillsLevelsBlocks.vue';
import SkillsLevelsComparison from '@/components/Skills/SkillsLevelsComparison.vue';

moment.updateLocale('fr', localization);

export default {
  props: {
    studentactivity: [Object, Array],
    slide: Number,
    tutorCanReadEvaluation: Boolean,
  },
  components: {
    AppHelpMessage,
    SkillsLevelsComparison,
    SkillsLevelsBlocks,
  },
  filters: {
    day(date) {
      return moment(date).format('D MMMM YYYY');
    },
    hour(date) {
      return moment(date).format('h:mm');
    },
  },
  data() {
    return {
      sequencetype: '5',
      isLoading: true,
      isFullPage: false,
      carousel: 0,
      animated: 'slide',
      drag: false,
      autoPlay: false,
      pauseHover: false,
      pauseInfo: false,
      repeat: false,
      pauseType: 'is-primary',
      interval: 3000,
      editDates: false,
      editor: ClassicEditor,
      isCardModalActive: null,
      imageUploaded: {},
      modalKey: 0,
      imageResized: null,
      file: null,
      started: true,
      edit: true,
      // trainingsequence: [],
      // studentsequence: [],
      // studentactivity: [],
    };
  },
  computed: {
    ...mapGetters(['getUserById']),
    ...mapState('auth', ['currentUser']),
    isTutorEvaluation() {
      if (this.studentactivity.legacy_activity.name_override === 'Évaluation tuteur') {
        return true;
      }
      if (this.studentactivity.legacy_activity.legacy_activity) {
        if (this.studentactivity.legacy_activity.legacy_activity.name === 'Évaluation tuteur') {
          return true;
        }
      }
      return false;
    },
  },
  methods: {
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    initActivity(idStudentActivity) {
      if (idStudentActivity !== '1') {
        this.$http
          .get(`/agenda/student_activity/${this.$route.params.idActivity}/?format=json`)
          .then((response) => {
            this.studentactivity = response.data;
            // this.endLoading();
          }).catch((error) => {
            window.console.log(error);
            this.errored = true;
          });
      }
    },
    saveStep() {

    },
    order(array) {
      return _.orderBy(array, 'position');
    },
    closeModal() {
      this.isCardModalActive = false;
      this.file = null;
    },
    validateActivity() {
      this.patchAllStepEntry();
      this.$buefy.dialog.confirm({
        title: 'Souhaitez-vous valider votre activité ?',
        message: 'N\'hésitez pas à bien relire votre réponse. Sinon, pas d\'inquiétude, vous pourrez la reprendre plus tard.',
        confirmText: 'Valider',
        cancelText: 'Relire',
        type: 'is-success',
        hasIcon: true,
        onConfirm: () => {
          this.studentactivity.status = 3;
          this.$http
            .patch(`/agenda/student_activity/${this.studentactivity.id}/`, {
              status: 3,
            })
            .then(() => {
              this.$buefy.toast.open({ message: 'Statut de l\'activité modifiée', type: 'is-success' });
              this.patchStatusSequence(3);
              this.$router.push({
                name: 'StudentSequenceDetail',
                params: {
                  idSequence: this.$route.params.idSequence,
                  idTraining: this.$route.params.idTraining,
                  idStudent: this.$route.params.idStudent,
                  idStudentTraining: this.$route.params.idStudentTraining,
                  idActivity: 1,
                  from: this.$route.params.name,
                },
              });
            }).catch((error) => {
              window.console.log(error);
              this.errored = true;
              this.$buefy.dialog.alert({
                title: 'Mince...',
                message: 'Le statut de l\'activité n\'a pas pu être modifiée.',
                type: 'is-info',
                hasIcon: true,
                icon: 'times-circle',
                ariaRole: 'alertdialog',
                ariaModal: true,
                confirmText: 'Ok',
              });
            });
        },
      });
    },
    patchAllStepEntry() {
      const arraySteps = this.studentactivity.student_activity_steps;
      // loop to patch each step entry
      for (let i = 0; i < arraySteps.length; i += 1) {
        const idStep = arraySteps[i].id;
        const entryContent = arraySteps[i].step_entry;
        this.$http
          .patch(`/agenda/student_activity_step/${idStep}/`, {
            step_entry: entryContent,
          }).then(() => {
            if (i === arraySteps.length - 1) { this.$buefy.toast.open({ message: 'Activité enregistrée', type: 'is-success' }); }
            this.endLoading();
          }).catch((error) => {
            window.console.log(error);
            this.errored = true;
            this.$buefy.dialog.alert({
              title: 'Mince...',
              message: 'L\'activité n\'a pas pu être enregistrée.',
              type: 'is-info',
              hasIcon: true,
              icon: 'times-circle',
              ariaRole: 'alertdialog',
              ariaModal: true,
              confirmText: 'Ok',
            });
          });
      }
    },
    modifyStatus() {
      this.$buefy.dialog.confirm({
        title: 'Souhaitez-vous modifier cette activité ?',
        message: 'Vous pouvez modifier le contenu de votre activité.',
        confirmText: 'Modifier',
        cancelText: 'Annuler',
        type: 'is-info',

        onConfirm: () => {
          this.$emit('update:isStepModalReadActive', false);
          this.$emit('messageFromActivity', this.studentactivity.id);
          this.$emit('update:isStepModalActive', true);
          // this.$http
          //   .patch(`/agenda/student_activity/${this.studentactivity.id}/`, {
          //     status: 1,
          //   })
          //   .then(() => {
          //     this.$emit('update:isStepModalReadActive', false);
          //     this.$emit('messageFromActivity', this.studentactivity.id);
          //     this.$emit('update:isStepModalActive', true);
          //   }).catch((error) => {
          //     window.console.log(error);
          //     this.errored = true;
          //     this.$buefy.dialog.alert({
          //       title: 'Mince...',
          //       message: 'Le statut de l\'activité n\'a pas pu être modifié.',
          //       type: 'is-info',
          //       hasIcon: true,
          //       icon: 'times-circle',
          //       ariaRole: 'alertdialog',
          //       ariaModal: true,
          //       confirmText: 'Ok',
          //     });
          //   });
        },
      });
    },
    logActivity() {
      const typeAction = 'lecture';
      this.$http
        .post('agenda/logs/', {
          action: typeAction,
          student_sequence: this.$route.params.idSequence,
          student_activity: this.$route.params.idActivity,
          training: this.$route.params.idTraining,
          student_training: this.$route.params.idStudentTraining,
        })
        .then(() => {
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    patchStatusSequence(statusSequence) {
      this.$http
        .patch(`/agenda/student_sequence/${this.$route.params.idSequence}/`, {
          status: statusSequence,
        }).then(() => {
          // this.initStudentSequence();
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Une erreur s\'est produite, veuillez réessayer. Si l\'erreur persiste vous pouvez contacter le support technique.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    validateDefEval() {
      this.patchAllStepEntry();
      this.$buefy.dialog.confirm({
        title: 'Souhaitez-vous valider définitivement votre activité ?',
        message: 'N\'hésitez pas à bien relire votre réponse. Sinon, pas d\'inquiétude, vous pourrez la reprendre plus tard.',
        confirmText: 'Valider',
        cancelText: 'Relire',
        type: 'is-success',
        hasIcon: true,
        onConfirm: () => {
          this.studentactivity.status = 4;
          this.$http
            .patch(`/agenda/student_activity/${this.studentactivity.id}/`, {
              status: 4,
            })
            .then(() => {
              this.patchStatusSequence(4);
              this.$buefy.toast.open({ message: 'Activité validée', type: 'is-success' });
              this.$router.push({
                name: 'StudentSequenceDetail',
                params: {
                  idSequence: this.$route.params.idSequence,
                  idTraining: this.$route.params.idTraining,
                  idStudent: this.$route.params.idStudent,
                  idStudentTraining: this.$route.params.idStudentTraining,
                  idActivity: 1,
                  from: this.$route.params.name,
                },
              });
            }).catch((error) => {
              window.console.log(error);
              this.errored = true;
              this.$buefy.dialog.alert({
                title: 'Mince...',
                message: 'Le statut de l\'activité n\'a pas pu être modifiée.',
                type: 'is-info',
                hasIcon: true,
                icon: 'times-circle',
                ariaRole: 'alertdialog',
                ariaModal: true,
                confirmText: 'Ok',
              });
            });
        },
      });
    },
    conflictAlert() {
      this.patchAllStepEntry();
      this.$buefy.dialog.confirm({
        title: 'Souhaitez-vous indiquer un litige à l\'équipe pédagogique ?',
        message: 'Si vous n\'arrivez pas à dialoguer avec votre apprenant et à vous mettre d\'accord sur l\'évaluation des compétences, vous pouvez l\'indiquer.',
        confirmText: 'Signaler',
        cancelText: 'Annuler',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.studentactivity.status = 7;
          this.$http
            .patch(`/agenda/student_activity/${this.studentactivity.id}/`, {
              status: 7,
            })
            .then(() => {
              this.$buefy.toast.open({ message: 'Litige indiqué', type: 'is-success' });
              this.patchStatusSequence(7);
              this.$router.push({
                name: 'StudentSequenceDetail',
                params: {
                  idSequence: this.$route.params.idSequence,
                  idTraining: this.$route.params.idTraining,
                  idStudent: this.$route.params.idStudent,
                  idStudentTraining: this.$route.params.idStudentTraining,
                  idActivity: 1,
                  from: this.$route.params.name,
                },
              });
            }).catch((error) => {
              window.console.log(error);
              this.errored = true;
              this.$buefy.dialog.alert({
                title: 'Mince...',
                message: 'Le statut de l\'activité n\'a pas pu être modifiée.',
                type: 'is-info',
                hasIcon: true,
                icon: 'times-circle',
                ariaRole: 'alertdialog',
                ariaModal: true,
                confirmText: 'Ok',
              });
            });
        },
      });
    },
    endConflict() {
      this.$buefy.dialog.confirm({
        title: 'Souhaitez-vous lever le conflit ?',
        message: 'Cela permettra à chacun de valider le semestre. Le tuteur pourra ainsi reprendre la comparaison des compétences évaluées avec son apprenant et valider.',
        confirmText: 'Valider',
        cancelText: 'Relire',
        type: 'is-success',
        hasIcon: true,
        onConfirm: () => {
          this.$http
            .patch(`/agenda/student_activity/${this.studentactivity.id}/`, {
              status: 3,
            })
            .then(() => {
              this.$buefy.toast.open({ message: 'Activité validée', type: 'is-success' });
              this.patchStatusSequence(3);
            }).catch((error) => {
              window.console.log(error);
              this.errored = true;
              this.$buefy.dialog.alert({
                title: 'Mince...',
                message: 'Le statut de l\'activité n\'a pas pu être modifié.',
                type: 'is-info',
                hasIcon: true,
                icon: 'times-circle',
                ariaRole: 'alertdialog',
                ariaModal: true,
                confirmText: 'Ok',
              });
            });
        },
      });
    },
  },
  created() {
    this.endLoading();
    this.carousel = this.slide;
    if (this.currentUser.role === 'apprenant' || this.currentUser.role === 'tuteur') { this.logActivity(); }
    // this.initActivity();
    // window.console.log(this.studentactivity);
  },
  mounted() {
  },
  destroyed() {
  },
};
</script>

<style lang="scss">
.ck-content {
  min-height: 10vh;
}
.modalStep .ck-content {
  min-height: 60vh;
}
.hero.is-medium .hero-body {
  padding: 3rem 1.5rem !important;
}
.carousel-arrow .icon.has-icons-left,
.carousel-arrow .icon.has-icons-right {
  top: 65px !important;
}
.modalStep .title-paragraph {
  text-align: center;
}
.modalStep {
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
}
.readonly {
  text-align: left !important;
}
</style>
