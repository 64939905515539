<template>
  <div class="modalbox modalStep" id="activityEdit">
    <b-loading
      :is-full-page="isFullPage"
      v-model="isLoading"
      class="loaderEdit"
    >
    </b-loading>
    <div class="is-flex bigmodal">
      <div class="mr-auto">
        <img
          class="btn-previous"
          :src="require('@/assets/img/arrows/previous.svg')"
          @click="$emit('close')"
        />
      </div>
    </div>
    <section class="w-100">
      <b-carousel
        v-model="carousel"
        :animated="animated"
        :has-drag="drag"
        :autoplay="autoPlay"
        :pause-hover="pauseHover"
        :pause-info="pauseInfo"
        :pause-info-type="pauseType"
        :interval="interval"
        :repeat="repeat"
        :arrow-hover="false"
        @change="slideend"
        :indicator="false"
      >
        <b-carousel-item
          v-for="(stepActivity, i) in order(
            studentactivity.student_activity_steps
          )"
          :key="i"
        >
          <section class="hero is-medium is-bold">
            <div class="">
              <div class="bloc-title">
                <div class="title-paragraph">
                  <div class="stepsCount">
                    {{ i + 1 }} /
                    {{ studentactivity.student_activity_steps.length }}
                  </div>
                  {{ stepActivity.legacy_activity_step.name }}
                </div>
                <div
                  v-if="sequencetype === 10 && studentactivity.position === 1"
                >
                  <div class="subtitle-paragraph mb-4 test">
                    <div
                      v-html="
                        stepActivity.legacy_activity_step.description_override
                      "
                      :class="
                        stepActivity.legacy_activity_step.description_override
                          .length
                          ? 'readonly'
                          : ''
                      "
                    ></div>
                  </div>
                </div>
                <div
                  class="subtitle-paragraph mb-4"
                  v-else
                  :class="
                    stepActivity.legacy_activity_step.content_type === 2
                      ? 'readonly'
                      : ''
                  "
                >
                  <div v-if="stepActivity.legacy_activity_step.content">
                    <div
                      v-html="stepActivity.legacy_activity_step.content"
                      :class="
                        stepActivity.legacy_activity_step.content.length > 200
                          ? 'readonly'
                          : ''
                      "
                    ></div>
                  </div>
                  <div v-else>
                    <div
                      v-html="
                        stepActivity.legacy_activity_step.placeholder_override
                      "
                      :class="
                        stepActivity.legacy_activity_step.placeholder_override
                          .length > 200
                          ? 'readonly'
                          : ''
                      "
                    ></div>
                  </div>
                </div>
              </div>
              <div class="container-editor">
                <div
                  class="w-100"
                  v-if="stepActivity.legacy_activity_step.content_type === 0"
                  style="position: relative"
                >
                  <div
                    v-if="
                      (studentactivity.sequence_type.id === 5 ||
                        studentactivity.sequence_type.name ===
                          'Situation de travail') &&
                      studentactivity.position === 1 &&
                      stepActivity.position === 1
                    "
                  >
                    <b-field>
                      <b-input
                        v-model="stepActivity.step_entry"
                        type="textarea"
                        placeholder="Inscrivez le titre ici"
                      >
                      </b-input>
                    </b-field>
                  </div>
                  <div v-else>
                    <ModalImage
                      v-if="isCardModalActive === i"
                      :fileImage.sync="imageUploaded"
                      :stepId="stepActivity.id"
                      :stepEntryContent="stepActivity.step_entry"
                      :imageResized.sync="imageResized"
                      @close="closeModal"
                      @loaded="
                        addImageinSteps(
                          stepActivity.id,
                          stepActivity.step_entry,
                          imageResized
                        )
                      "
                    />
                    <div class="imgBottom fade is-flex" v-else>
                      <b-upload
                        v-model="file"
                        class="file-label"
                        @input="uploadImageModal(i)"
                      >
                        <span class="file-cta">
                          <b-icon class="file-icon" icon="photo"></b-icon>
                        </span>
                      </b-upload>
                      <!-- <b-button @click="fullscreen = !fullscreen"
                      >fullscreen</b-button
                    > -->
                    </div>
                    <div class="is-flex blockEditContent">
                      <b-field
                        class="file is-primary mb-1 addImage"
                        v-if="
                          (studentactivity.sequence_type.id === 5 ||
                            studentactivity.sequence_type.name ===
                              'Situation de travail') &&
                          studentactivity.position === 1 &&
                          stepActivity.position === 1
                        "
                      >
                        <span class="file-cta pl-0 pr-0"></span
                      ></b-field>
                      <b-field
                        v-else
                        class="file is-primary mb-1 addImage"
                        :class="{ 'has-name': !!file }"
                      >
                        <b-upload
                          v-model="file"
                          class="file-label"
                          @input="uploadImageModal(i)"
                        >
                          <span class="file-cta">
                            <b-icon class="file-icon" icon="photo"></b-icon>
                            <span class="file-label">Ajouter une image</span>
                          </span>
                        </b-upload>
                      </b-field>
                    </div>
                    <div
                      :id="'s' + i"
                      class="ck-editor-block"
                      :class="fullscreen ? 'fullscreen' : ''"
                    >
                      <ckeditor
                        v-if="displayck"
                        v-model="stepActivity.step_entry"
                        :editor="editor"
                        :config="editorConfig"
                        class="mb-5"
                        :ref="'s' + i"
                      ></ckeditor>
                    </div>
                  </div>
                </div>
                <div
                  v-if="stepActivity.legacy_activity_step.content_type === 1"
                  class="mt-5 pt-5"
                  id="blockEval"
                >
                  <div class="subtitle-paragraph mb-4"></div>
                  <SkillEvaluationSelect
                    :role="studentactivity.rights"
                    type="sequence"
                    @postEval="
                      populateStepEntry(
                        stepActivity.id,
                        stepActivity.step_entry
                      )
                    "
                  />
                  <!-- <SkillEvaluationSelect
                    :role="studentactivity.rights"
                    type="sequence"
                    @saveActivity="patchAllStepEntry"
                    @displayBtn="displayBtn"
                  /> -->
                  Vous pouvez ajouter un commentaire ici
                  <div :id="'s' + i" class="ck-editor-block blockEditContent">
                    <ckeditor
                      v-if="displayck"
                      v-model="stepActivity.step_entry"
                      :editor="editor"
                      :config="editorConfig"
                      class="mb-5"
                      :ref="'s' + i"
                    ></ckeditor>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </b-carousel-item>
        <b-carousel-item v-if="!isTutorEvaluation">
          <div class="end-bloc">
            <div class="bloc-title">
              <div class="title-paragraph">Vous avez terminé ?</div>
              <b-message v-if="notAllFields" type="is-warning"
                >Attention certains champs de sont pas saisis</b-message
              >
              <!-- <b-message v-else type="is-success"
                >Tous les champs sont remplis, si vous avez bien relu, vous
                pouvez valider.</b-message
              > -->
              <div>
                <div class="subtitle-paragraph mb-4" v-if="!notAllFields">
                  Vous pouvez valider cette activité et passer à la suivante !
                </div>
                <b-button
                  :disabled="notAllFields"
                  @click="validateActivity"
                  class="btn-action w-auto"
                  type="is-success is-rounded"
                  rounded
                  >valider</b-button
                >
              </div>
            </div>
          </div>
        </b-carousel-item>
        <b-carousel-item
          v-if="
            isTutorEvaluation &&
            studentactivity.rights.includes('w_tuteur') &&
            studentactivity.status === 3
          "
        >
          <section class="hero is-medium is-bold">
            <div class="">
              <div class="bloc-title">
                <div class="title-paragraph">
                  Comparez votre évaluation avec celle de votre apprenant•e
                </div>
                <div>
                  <div class="subtitle-paragraph mb-4 test">
                    <div>
                      Comparez maintenant vos évaluations avec celles de votre
                      apprenant•e. L'enjeu est de bien discuter avec votre
                      apprenant•e de ce qui a été vu, travaillé, acquis et
                      l'aider à identifier et comprendre où il en est. Vous
                      pouvez encore modifier ajuster votre évaluation.
                      Toutefois, si vraiment vous n'êtes pas d'accord sur le
                      constat, vous pouvez indiquer un litige. Cela permettra à
                      l'équipe pédagogique d'identifier qu'il y a un souci.
                    </div>
                  </div>
                </div>
                <div class="has-text-centered">
                  <b-button
                    type="is-success"
                    class="mr-2"
                    @click="validateDefEval"
                    rounded
                    >valider</b-button
                  ><b-button
                    type="is-danger"
                    rounded
                    outlined
                    @click="conflictAlert()"
                    >indiquer un litige</b-button
                  >
                </div>
                <SkillsLevelsComparison
                  :key="componentKey"
                  v-if="studentactivity.rights.includes('w_tuteur')"
                  :role="studentactivity.rights"
                  type="sequence"
                />
                <div class="has-text-centered pb-5 mb-5">
                  <b-button
                    type="is-success"
                    class="mr-2"
                    @click="validateDefEval"
                    rounded
                    >valider</b-button
                  ><b-button
                    type="is-danger"
                    rounded
                    outlined
                    @click="conflictAlert()"
                    >indiquer un litige</b-button
                  >
                </div>
              </div>
            </div>
          </section>
        </b-carousel-item>
      </b-carousel>
    </section>
    <div v-if="studentactivity.rights.includes('w_' + currentUser.role)">
      <div
        v-if="!end"
        class="is-flex is-flex-wrap-wrap activityBtns align-items-center"
        style="z-index: 2000"
      >
        <div class="w-100 is-flex">
          <div class="mx-auto">
            <b-button
              @click="patchAllStepEntry"
              class="btn-action w-auto mr-1"
              type="is-primary is-rounded"
              rounded
              >enregistrer</b-button
            >
            <span
              class="has-text-centered"
              v-if="
                isTutorEvaluation && studentactivity.rights.includes('w_tuteur')
              "
            >
              <b-button
                type="is-success"
                class="mr-2"
                rounded
                @click="validateEval"
                >valider</b-button
              >
            </span>
          </div>
        </div>
        <div class="help-btn">
          <AppHelpMessage
            title="Rédigez et enregistrez votre activité"
            message="<b>Vous pouvez enregistrer vos notes autant de fois que
                     vous le souhaitez.</b> Tant que vous ne validez pas votre travail,
                      l'activité n'est pas considérée comme effectuée.</b>
            <br/>Une fois validée, vous pourrez corriger des fautes ou reprendre le contenu.
               <b>Toutefois si vous refaites cette situation, il vaut mieux utiliser le bouton
                  'Vous avez progressé' sur la vue précédente pour ajouter de nouvelles notes.</b>"
            textBtn="Besoin d'aide ?"
          />
        </div>
      </div>
    </div>
    <div class="container contentSequence pb-5 mb-5"></div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import moment from 'moment';
import localization from 'moment/locale/fr';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/fr';
import _ from 'lodash';
import ModalImage from '@/components/Resources/ModalImage.vue';
import AppHelpMessage from '@/components/AppHelpMessage.vue';
import SkillEvaluationSelect from '@/components/Skills/SkillEvaluationSelect.vue';
import SkillsLevelsComparison from '@/components/Skills/SkillsLevelsComparison.vue';
// import SkillsTrainingVue from '../Skills/SkillsTraining.vue';

moment.updateLocale('fr', localization);

export default {
  props: {
    studentactivity: [Object, Array],
    slide: Number,
  },
  components: {
    ckeditor: CKEditor.component,
    ModalImage,
    AppHelpMessage,
    SkillEvaluationSelect,
    SkillsLevelsComparison,
  },
  filters: {
    day(date) {
      return moment(date).format('D MMMM YYYY');
    },
    hour(date) {
      return moment(date).format('h:mm');
    },
  },
  data() {
    return {
      fullscreen: false,
      displayck: false,
      sequencetype: '5',
      isLoading: false,
      isFullPage: false,
      carousel: 0,
      animated: 'slide',
      drag: false,
      autoPlay: false,
      pauseHover: false,
      pauseInfo: false,
      repeat: false,
      pauseType: 'is-primary',
      interval: 1000,
      editDates: false,
      editor: ClassicEditor,
      editorDisabled: true,
      editorConfig: {
        language: 'fr',
        toolbar: ['heading', '|', 'bold', 'italic', 'bulletedList', 'numberedList', '|', 'outdent', 'indent', '|', 'link', '|', 'undo',
          'redo'],
        alignment: {
          options: ['left', 'right'],
        },
        heading: {
          options: [
            { model: 'paragraph', title: 'Styles de texte', class: 'ck-heading_paragraph' },
            {
              model: 'heading1',
              view: 'h1',
              title: 'Titre 1',
              class: 'ck-heading_heading1',
            },
            {
              model: 'heading2',
              view: 'h2',
              title: 'Titre 2',
              class: 'ck-heading_heading2',
            },
            {
              model: 'mark',
              view: 'mark',
              title: 'surligné',
              class: 'ck-mark',
            },
          ],
        },
        table: {
          contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
        },
      },
      isCardModalActive: null,
      imageUploaded: {},
      modalKey: 0,
      imageResized: null,
      file: null,
      started: true,
      sequencestatus: null,
      end: false,
      displayBtnEval: false,
      currentSlide: null,
      tutor: null,
      tutors: null,
      componentKey: 0,
    };
  },
  computed: {
    ...mapGetters(['getUserById']),
    ...mapState('auth', ['currentUser']),
    notAllFields() {
      let errored = false;
      this.studentactivity.student_activity_steps.forEach((step) => {
        if (step.step_entry === null || step.step_entry === '') {
          if (step.step_entry !== '&nbsp;' && step.legacy_activity_step.content_type !== 1) {
            errored = true;
            return false;
          }
        }
        return errored;
      });
      return errored;
    },
    isTutorEvaluation() {
      if (this.studentactivity.legacy_activity.name_override === 'Évaluation tuteur') {
        return true;
      }
      if (this.studentactivity.legacy_activity.legacy_activity) {
        if (this.studentactivity.legacy_activity.legacy_activity.name === 'Évaluation tuteur') {
          return true;
        }
      }
      return false;
    },
  },
  methods: {
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    // initActivity(idStudentActivity) {
    //   if (idStudentActivity !== '1') {
    //     this.$http
    //       .get(`/agenda/student_activity/${this.$route.params.idActivity}/?format=json`)
    //       .then((response) => {
    //         this.studentactivity = response.data;
    //       }).catch((error) => {
    //         window.console.log(error);
    //         this.errored = true;
    //       });
    //   }
    // },
    initStatusSequence() {
      this.$http
        .get(`/agenda/student_sequence/${this.$route.params.idSequence}/?query={status,tutor,tutors_ids}&format=json`)
        .then((response) => {
          this.sequencestatus = response.data.status;
          this.tutor = response.data.tutor;
          this.tutors = response.data.tutors_ids;
          // this.endLoading();
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    order(array) {
      return _.orderBy(array, 'position');
    },
    uploadImageModal(index) {
      this.checkFile();
      if (this.fileValidity === true) {
        // const image = this.file.name;
        this.imageUploaded = this.file;
        this.modalKey += 1;
        this.isCardModalActive = index;
      }
    },
    closeModal() {
      this.isCardModalActive = false;
      this.file = null;
    },
    addImageinSteps(idStep, stepEntry, url) {
      let entryContent = '';
      if (stepEntry === null) {
        entryContent = `<div class="imageUploaded"><img src="${url}"/></div>`;
      } else {
        entryContent = `${stepEntry} <div class="imageUploaded"><img src="${url}"/></div>`;
      }
      this.$http
        .patch(`/agenda/student_activity_step/${idStep}/`, {
          step_entry: entryContent,
        }).then(() => {
          this.$emit('messageFromActivity', this.studentactivity.id);
          // this.initActivity();
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.endLoading();
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'L\'image n\'a pas pu être ajoutée au champ de texte.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    checkFile() {
      const extension = this.file.name.split('.').pop();
      // this.resources_type = this.file.substr((this.file.lastIndexOf('.') + 1));
      if (!/(jpeg|png|jpg)$/ig.test(extension)) {
        this.$buefy.dialog.alert({
          title: 'Ce type de fichier n\'est pas accepté',
          message: 'Vous pouvez utiliser les formats suivants :  jpeg, jpg, png.',
          type: 'is-info',
          hasIcon: true,
          icon: 'times-circle',
          ariaRole: 'alertdialog',
          ariaModal: true,
          confirmText: 'Ok',
        });
      } else if (this.file.size > 10000000) {
        this.$buefy.dialog.alert({
          title: 'Cette image est trop volumineuse',
          message: 'Vous pouvez télécharger une image dont la taille ne dépasse pas 10 Mo. Vous pouvez utiliser un service externe comme squoosh.app ou utiliser les options de votre périphérique (téléphone ou tablette) pour réduire une image.',
          type: 'is-info',
          hasIcon: true,
          icon: 'times-circle',
          ariaRole: 'alertdialog',
          ariaModal: true,
          confirmText: 'Ok',
        });
      } else if (this.file.name.length > 116) {
        this.$buefy.dialog.alert({
          title: 'Le nom de votre fichier est trop long',
          message: 'le nom ne doit pas dépasser 117 cractères',
          type: 'is-info',
          hasIcon: true,
          icon: 'times-circle',
          ariaRole: 'alertdialog',
          ariaModal: true,
          confirmText: 'Ok',
        });
      } else { this.nameFile = this.file.name; this.fileValidity = true; }
      // file.files[0].size
    },
    addImg(idStep, stepEntry) {
      this.checkFile();
      if (this.fileValidity === true) {
        const reader = new FileReader();
        reader.readAsDataURL(this.file);
        document.querySelector('#before').src = reader.readAsDataURL(this.file);
        fetch(this.file)
          .then((res) => res.blob())
          .then((blob) => {
            // const file = new File([blob], "File name", { type: "image/png" })
            const myfile = new File([blob], this.file.name, { type: this.file.type });
            const formData = new FormData();
            formData.append('url', myfile);
            formData.append('name', this.file.name);
            this.patchAllStepEntry();
            this.startLoading();
            this.$http
              .post('/images/', formData, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              })
              .then((response) => {
                this.endLoading();
                this.$buefy.toast.open({ message: 'Image ajoutée', type: 'is-success' });
                //     const step = this.studentactivity.student_activity_steps[i].step_entry;
                //     this.studentactivity.student_activity_steps[i].step_entry =
                // `${step} <img src="${response.data.file}"/>`;
                let entryContent = '';
                if (stepEntry === null) {
                  entryContent = `<div class="imageUploaded"><img src="${response.data.url}"/></div>`;
                } else {
                  entryContent = `${stepEntry} <div class="imageUploaded"><img src="${response.data.url}"/></div>`;
                }
                this.$http
                  .patch(`/agenda/student_activity_step/${idStep}/`, {
                    step_entry: entryContent,
                  }).then(() => {
                    this.$emit('messageFromActivity', this.studentactivity.id);
                  }).catch((error) => {
                    window.console.log(error);
                    this.errored = true;
                    this.endLoading();
                    this.$buefy.dialog.alert({
                      title: 'Mince...',
                      message: 'L\'image n\'a pas pu être ajoutée au champ de texte.',
                      type: 'is-info',
                      hasIcon: true,
                      icon: 'times-circle',
                      ariaRole: 'alertdialog',
                      ariaModal: true,
                      confirmText: 'Ok',
                    });
                  });
              }).catch((error) => {
                window.console.log(error);
                this.errored = true;
                this.$buefy.dialog.alert({
                  title: 'Mince...',
                  message: 'L\'image n\'a pas pu être ajoutée. ',
                  type: 'is-info',
                  hasIcon: true,
                  icon: 'times-circle',
                  ariaRole: 'alertdialog',
                  ariaModal: true,
                  confirmText: 'Ok',
                });
              });
          });
      }
    },
    patchStep(idStep, entryContent) {
      this.$http
        .patch(`/agenda/student_activity_step/${idStep}/`, {
          step_entry: entryContent,
        }).then(() => {
          this.$buefy.toast.open({ message: 'Activité enregistrée', type: 'is-success' });
          this.endLoading();
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'L\'activité n\'a pas pu être enregistrée.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    patchAllStepEntry() {
      const arraySteps = this.studentactivity.student_activity_steps;
      // loop to patch each step entry
      for (let i = 0; i < arraySteps.length; i += 1) {
        const idStep = arraySteps[i].id;
        const entryContent = arraySteps[i].step_entry;
        this.$http
          .patch(`/agenda/student_activity_step/${idStep}/`, {
            step_entry: entryContent,
          }).then(() => {
            if (i === arraySteps.length - 1) {
              this.$buefy.toast.open({ message: 'Toutes les étapes ont été enregistrées', type: 'is-success' });
              if (this.studentactivity.status !== 6) {
                this.patchStatusActivity(6);
                if (this.sequencestatus !== 6) {
                  this.patchStatusSequence(6);
                }
              }
            }
            this.endLoading();
          }).catch((error) => {
            window.console.log(error);
            this.errored = true;
            this.$buefy.dialog.alert({
              title: 'Mince...',
              message: 'L\'activité n\'a pas pu être enregistrée.',
              type: 'is-info',
              hasIcon: true,
              icon: 'times-circle',
              ariaRole: 'alertdialog',
              ariaModal: true,
              confirmText: 'Ok',
            });
          });
      }
      if ((this.studentactivity.sequence_type.id === 5
        || this.studentactivity.sequence_type.name === 'Situation de travail') && this.studentactivity.position === 1) {
        const indexTitle = _.findKey(this.studentactivity.student_activity_steps,
          ['position', 1]);
        this.$http
          .patch(`/agenda/student_sequence/${this.$route.params.idSequence}/`, {
            subtitle: this.studentactivity.student_activity_steps[indexTitle].step_entry,
          }).catch((error) => {
            window.console.log(error);
            this.errored = true;
          });
      }
    },
    validateActivity() {
      this.patchAllStepEntry();
      this.$buefy.dialog.confirm({
        title: 'Souhaitez-vous valider votre activité ?',
        message: 'N\'hésitez pas à bien relire votre réponse. Sinon, pas d\'inquiétude, vous pourrez la reprendre plus tard.',
        confirmText: 'Valider',
        cancelText: 'Relire',
        type: 'is-success',
        hasIcon: true,
        onConfirm: () => {
          this.studentactivity.status = 3;
          this.$http
            .patch(`/agenda/student_activity/${this.studentactivity.id}/`, {
              status: 3,
            })
            .then(() => {
              this.$buefy.toast.open({ message: 'Statut de l\'activité modifiée', type: 'is-success' });
              if (this.currentUser.role === 'apprenant' && this.studentactivity.rights.includes('w_tuteur')) {
                let arrayrecipients = [];
                if (this.tutor) {
                  arrayrecipients.push(this.tutor);
                } else {
                  arrayrecipients = this.tutors;
                }

                const titlemessage = 'Relisez l\'activité rédigée avec votre apprenant•e';
                const textmessage = `Vous venez d'enregistrer une activité avec ${this.currentUser.name}. Pouvez-vous la relire depuis votre compte inSitu ?
             <a href="https://app.insituapp.io/sequence-edition/${this.$route.params.idTraining}/${this.$route.params.idSequence}/${this.$route.params.idStudentTraining}/">Voir l'activité</a>`;
                const toast = 'email envoyé au tuteur';
                this.emailUser(arrayrecipients, titlemessage, textmessage, toast);
              } else {
                this.$router.push({
                  name: 'StudentSequenceDetail',
                  params: {
                    idSequence: this.$route.params.idSequence,
                    idTraining: this.$route.params.idTraining,
                    idStudent: this.$route.params.idStudent,
                    idStudentTraining: this.$route.params.idStudentTraining,
                    idActivity: 1,
                    from: this.$route.params.name,
                  },
                });
              }
            }).catch((error) => {
              window.console.log(error);
              this.errored = true;
              this.$buefy.dialog.alert({
                title: 'Mince...',
                message: 'Le statut de l\'activité n\'a pas pu être modifiée.',
                type: 'is-info',
                hasIcon: true,
                icon: 'times-circle',
                ariaRole: 'alertdialog',
                ariaModal: true,
                confirmText: 'Ok',
              });
            });
        },
      });
    },
    validateEval() {
      this.patchAllStepEntry();
      this.$buefy.dialog.confirm({
        title: 'Souhaitez-vous valider votre activité ?',
        message: 'N\'hésitez pas à bien relire votre réponse. Sinon, pas d\'inquiétude, vous pourrez la reprendre plus tard.',
        confirmText: 'Valider',
        cancelText: 'Relire',
        type: 'is-success',
        hasIcon: true,
        onConfirm: () => {
          this.studentactivity.status = 3;
          this.$http
            .patch(`/agenda/student_activity/${this.studentactivity.id}/`, {
              status: 3,
            })
            .then(() => {
              this.$buefy.toast.open({ message: 'Statut de l\'activité modifiée', type: 'is-success' });
              this.changeSlide();
            }).catch((error) => {
              window.console.log(error);
              this.errored = true;
              this.$buefy.dialog.alert({
                title: 'Mince...',
                message: 'Le statut de l\'activité n\'a pas pu être modifiée.',
                type: 'is-info',
                hasIcon: true,
                icon: 'times-circle',
                ariaRole: 'alertdialog',
                ariaModal: true,
                confirmText: 'Ok',
              });
            });
        },
      });
    },
    validateDefEval() {
      this.patchAllStepEntry();
      this.$buefy.dialog.confirm({
        title: 'Souhaitez-vous valider définitivement votre activité ?',
        message: 'N\'hésitez pas à bien relire votre réponse. Sinon, pas d\'inquiétude, vous pourrez la reprendre plus tard.',
        confirmText: 'Valider',
        cancelText: 'Relire',
        type: 'is-success',
        hasIcon: true,
        onConfirm: () => {
          this.studentactivity.status = 4;
          this.$http
            .patch(`/agenda/student_activity/${this.studentactivity.id}/`, {
              status: 4,
            })
            .then(() => {
              this.$buefy.toast.open({ message: 'Activité validée', type: 'is-success' });
              this.patchStatusSequence(4);
              this.$router.push({
                name: 'StudentSequenceDetail',
                params: {
                  idSequence: this.$route.params.idSequence,
                  idTraining: this.$route.params.idTraining,
                  idStudent: this.$route.params.idStudent,
                  idStudentTraining: this.$route.params.idStudentTraining,
                  idActivity: 1,
                  from: this.$route.params.name,
                },
              });
            }).catch((error) => {
              window.console.log(error);
              this.errored = true;
              this.$buefy.dialog.alert({
                title: 'Mince...',
                message: 'Le statut de l\'activité n\'a pas pu être modifiée.',
                type: 'is-info',
                hasIcon: true,
                icon: 'times-circle',
                ariaRole: 'alertdialog',
                ariaModal: true,
                confirmText: 'Ok',
              });
            });
        },
      });
    },
    logActivity() {
      const typeAction = 'écriture';
      this.$http
        .post('agenda/logs/', {
          action: typeAction,
          student_sequence: this.$route.params.idSequence,
          student_activity: this.$route.params.idActivity,
          training: this.$route.params.idTraining,
          student_training: this.$route.params.idStudentTraining,
        })
        .then(() => {
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    emailUser(listrecipients, titletext, messagetext, toast) {
      this.$http
        .post('/notifications/send_email/', {
          title: titletext,
          message: messagetext,
          recipients: listrecipients,
          sender: this.currentUser.id,
        }).then(() => {
          this.$buefy.toast.open({ message: `${toast}`, type: 'is-success' });
          this.$router.push({
            name: 'StudentSequenceDetail',
            params: {
              idSequence: this.$route.params.idSequence,
              idTraining: this.$route.params.idTraining,
              idStudent: this.$route.params.idStudent,
              idStudentTraining: this.$route.params.idStudentTraining,
              idActivity: 1,
              from: this.$route.params.name,
            },
          });
        }).catch((error) => {
          window.console.log(error);
          this.issue = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Votre notification n\'a pas pu être envoyée',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    patchStatusSequence(statusSequence) {
      this.$http
        .patch(`/agenda/student_sequence/${this.$route.params.idSequence}/`, {
          status: statusSequence,
        }).then(() => {
          // this.initStudentSequence();
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Une erreur s\'est produite, veuillez réessayer. Si l\'erreur persiste vous pouvez contacter le support technique.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    patchStatusActivity(statusActivity) {
      this.$http
        .patch(`/agenda/student_activity/${this.$route.params.idActivity}/`, {
          status: statusActivity,
        }).then(() => {
          this.$buefy.toast.open({ message: 'Statut de l\'activité modifiée', type: 'is-success' });
          this.studentactivity.status = statusActivity;
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Une erreur s\'est produite, veuillez réessayer. Si l\'erreur persiste vous pouvez contacter le support technique.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    forceRerender() {
      // Every time that forceRerender is called, our prop componentKey will change.
      // and Vue knows that it has to destroy the component and create a new one.
      // to not have searching values in others tables
      this.componentKey += 1;
    },
    slideend() {
      if (this.isTutorEvaluation) {
        this.forceRerender();
      }
      if (this.carousel === this.studentactivity.student_activity_steps.length) {
        this.end = true;
      } else {
        this.end = false;
        this.currentSlide = this.carousel;
      }
    },
    patchAndEval() {

    },
    displayBtn() {
      this.displayBtnEval = true;
    },
    populateStepEntry(id, stepcontent) {
      if (!stepcontent) {
        this.$http
          .patch(`/agenda/student_activity_step/${id}/`, {
            step_entry: '&nbsp;',
          }).then(() => {
            this.endLoading();
            if (this.studentactivity.status === 0) {
              this.patchStatusActivity(6);
            }
          }).catch((error) => {
            window.console.log(error);
            this.errored = true;
            this.$buefy.dialog.alert({
              title: 'Mince...',
              message: 'L\'activité n\'a pas pu être enregistrée.',
              type: 'is-info',
              hasIcon: true,
              icon: 'times-circle',
              ariaRole: 'alertdialog',
              ariaModal: true,
              confirmText: 'Ok',
            });
          });
      }
    },
    conflictAlert() {
      this.patchAllStepEntry();
      this.$buefy.dialog.confirm({
        title: 'Souhaitez-vous indiquer un litige à l\'équipe pédagogique ?',
        message: 'Si vous n\'arrivez pas à dialoguer avec votre apprenant et à vous mettre d\'accord sur l\'évaluation des compétences, vous pouvez l\'indiquer.',
        confirmText: 'Signaler',
        cancelText: 'Annuler',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.studentactivity.status = 7;

          this.$http
            .patch(`/agenda/student_activity/${this.$route.params.idActivity}/`, {
              status: 7,
            })
            .then(() => {
              this.$buefy.toast.open({ message: 'Litige activité indiqué', type: 'is-success' });
              this.patchStatusSequence(7);
              this.$router.push({
                name: 'StudentSequenceDetail',
                params: {
                  idSequence: this.$route.params.idSequence,
                  idTraining: this.$route.params.idTraining,
                  idStudent: this.$route.params.idStudent,
                  idStudentTraining: this.$route.params.idStudentTraining,
                  idActivity: 1,
                  from: this.$route.params.name,
                },
              });
            }).catch((error) => {
              window.console.log(error);
              this.errored = true;
              this.$buefy.dialog.alert({
                title: 'Mince...',
                message: 'Le statut de l\'activité n\'a pas pu être modifiée.',
                type: 'is-info',
                hasIcon: true,
                icon: 'times-circle',
                ariaRole: 'alertdialog',
                ariaModal: true,
                confirmText: 'Ok',
              });
            });
        },
      });
    },
    changeSlide() {
      this.carousel = 2;
    },
  },
  created() {
    this.startLoading();
    this.carousel = this.slide;
    if (this.currentUser.role === 'apprenant' || this.currentUser.role === 'tuteur') { this.logActivity(); }
    this.$emit('endloader');
    setTimeout(() => {
      this.displayck = true;
      this.endLoading();
    }, 1);
    this.initStatusSequence();
    if (this.studentactivity.rights.includes('w_tuteur')) {
      if (this.currentUser.role === 'apprenant') {
        window.console.log('attention');
        this.$buefy.dialog.confirm({
          title: 'Votre tuteur est-il bien avec vous pour rédiger cette activité ?',
          message: 'En cliquant sur "oui" vous certifiez être en présence de votre tuteur. Sachez que votre tuteur sera notifié et que votre responsabilité seule sera engagée si ce n\'était pas vrai.',
          hasIcon: true,
          cancelText: 'Non, faire plus tard',
          confirmText: 'OUI, je le certifie',
          type: 'is-success',
          onConfirm: () => this.$buefy.toast.open('Vous pouvez rédiger !'),
          onCancel: () => this.$router.push({
            name: 'StudentSequenceDetail',
            params: {
              idSequence: this.$route.params.idSequence,
              idTraining: this.$route.params.idTraining,
              idStudent: this.$route.params.idStudent,
              idStudentTraining: this.$route.params.idStudentTraining,
              idActivity: 1,
              from: this.$route.params.name,
            },
          }),
        });
      }
    }
  },
  mounted() {
  },
  destroyed() {
  },
};
</script>

<style lang="scss" scoped>
// .ck-content {
//   min-height: 10vh;
// }
// .modalStep .ck-content {
//   min-height: 60vh;
// }
// .hero.is-medium .hero-body {
//   padding: 3rem 1.5rem !important;
// }
// .carousel-arrow .icon.has-icons-left,
// .carousel-arrow .icon.has-icons-right {
//   top: 65px !important;
// }
// .modalStep .title-paragraph {
//   text-align: center;
// }
// .modalStep {
//   position: absolute;
//   z-index: 1000;
//   top: 0;
//   left: 0;
//   width: 100%;
//   background-color: #fff;
// }
.loading-overlay {
  z-index: 3000;
}
</style>
